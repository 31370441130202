












































import type { PropType } from '@nuxtjs/composition-api';
import { defineComponent, nextTick, onUnmounted, ref, watch } from '@nuxtjs/composition-api';
import { SfBreadcrumbs, SfButton, SfDropdown, SfLink } from '@storefront-ui/vue';
import SvgImage from '~/components/General/SvgImage.vue';
import { addScrollbarWidthPadding, removeScrollbarWidthPadding } from '~/composables/utils/addScrollbarWidthPadding';
import { Breadcrumb, MobileBreadcrumbMenu } from '~/modules/catalog/types';

export default defineComponent({
  name: 'DynamicNavigation',
  components: {
    SfBreadcrumbs,
    SfLink,
    SfDropdown,
    SfButton,
    SvgImage,
  },
  props: {
    breadcrumbs: {
      type: Array as PropType<Breadcrumb[]>,
      required: true,
    },
    mobileBreadcrumb: {
      type: Object as PropType<Breadcrumb | null>,
      required: false,
    },
    withBackArrow: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideOnMobile: {
      type: Boolean,
      default: false,
    },
    mobileMenu: {
      type: Object as PropType<MobileBreadcrumbMenu>,
      required: false,
    },
  },
  setup() {
    const isMenuOpen = ref(false);

    const onClose = () => {
      isMenuOpen.value = false;
    };

    watch(isMenuOpen, (newValue) => {
      if (!newValue) {
        document.documentElement.classList.remove('no-scroll');
        removeScrollbarWidthPadding();
        return;
      }
      // Menu has opened
      nextTick(() => {
        const overlay = document.querySelector('.sf-dropdown__overlay');
        if (overlay) {
          addScrollbarWidthPadding();
          document.documentElement.classList.add('no-scroll');
          overlay.addEventListener('click', onClose);
        }
      });
    });

    onUnmounted(() => {
      document.documentElement.classList.remove('no-scroll');
      removeScrollbarWidthPadding();
    });

    return {
      isMenuOpen,
    };
  },
});
