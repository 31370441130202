


































































import { computed, defineComponent, ref, useContext, useRoute } from '@nuxtjs/composition-api';
import { SfButton, SfLink, SfModal } from '@storefront-ui/vue';
import SvgImage from '~/components/General/SvgImage.vue';
import DynamicNavigation from '~/components/Navigation/DynamicNavigation.vue';
import { useCart, useUiState, useWishlist } from '~/composables';
import { Breadcrumb } from '~/modules/catalog/types';
import LoginForm from '~/modules/customer/components/LoginModal/forms/LoginForm.vue';
import { LoginWithBankId, LoginWithCode, LoginWithPassword, LoginWithSSN } from '~/modules/customer/types/form';
import BankIdLoginForm from '../../components/LoginModal/forms/BankIdLoginForm.vue';
import CodeLoginForm from '../../components/LoginModal/forms/CodeLoginForm.vue';
import SSNLoginForm from '../../components/LoginModal/forms/SSNLoginForm.vue';
import useUser from '../../composables/useUser';
import BankIdAnchor from './BankIdAnchor.vue';

export default defineComponent({
  name: 'Login',
  components: {
    LoginForm,
    BankIdLoginForm,
    SSNLoginForm,
    CodeLoginForm,
    DynamicNavigation,
    SfLink,
    BankIdQrCodeModal: () => import(/* webpackPrefetch: true */ '~/modules/customer/components/BankIdQrCodeModal.vue'),
    SvgImage,
    BankIdAnchor,
    SfModal,
    SfButton,
  },
  middleware: 'is-not-authenticated',
  setup() {
    const route = useRoute();
    const { isBankIdQrCodeModalOpen } = useUiState();
    const { login, loading, error: userError, ssnLogin, getCodeLoading, codeReceived, codeLogin, resetCodeReceived } = useUser();
    const { load: loadCart } = useCart();
    const { loadItemsCount } = useWishlist();
    const { localePath, i18n } = useContext();

    const isModalVisible = ref(false);

    const loginWithEmail = computed(() => route.value?.query?.activate_email === 'true');

    const withPasswordForm = ref<LoginWithPassword>({
      email: '',
      password: '',
    });

    const bankIdForm = ref<LoginWithBankId>({
      socialSecurityNumber: null,
      isOnAnotherDevice: false,
    });

    const ssnForm = ref<LoginWithSSN>({
      socialSecurityNumber: null,
    });

    const codeForm = ref<LoginWithCode>({
      authCode: null,
      socialSecurityNumber: null,
    });

    const breadcrumbs = ref<Breadcrumb[]>([
      {
        text: i18n.t('Home') as string,
        link: localePath('/'),
      },
      {
        text: i18n.t('Login') as string,
        link: localePath('/login'),
      },
    ]);

    // @ts-expect-error Recaptcha is not registered as a Nuxt module. Its absence is handled in the code
    const { $recaptcha, $config } = useContext();
    const isRecaptchaEnabled = ref<boolean>(typeof $recaptcha !== 'undefined' && Boolean($config.isRecaptcha));
    const qrCodeValue = ref<string>('');

    const getRecaptchaInfo = async (isRecaptchaOn: boolean): Promise<{ token: string | null; cleanup: () => void }> => {
      if (isRecaptchaOn) {
        $recaptcha.init();
        return {
          token: await $recaptcha.getResponse(),
          cleanup: () => {
            $recaptcha.reset();
          },
        };
      }
      return { token: null, cleanup: () => {} };
    };

    const onBankIdLoginFormSubmit = async (form: LoginWithBankId) => {
      bankIdForm.value = form;
      const { token, cleanup } = await getRecaptchaInfo(isRecaptchaEnabled.value);
      await login({
        user: { socialSecurityNumber: form.socialSecurityNumber, ...(token ? { recaptchaToken: token } : {}) },
        isWithBankId: true,
        isOnAnotherDevice: form.isOnAnotherDevice,
      });
      cleanup();
      if (!userError.value.login) {
        await Promise.all([loadItemsCount(), loadCart()]);
      }
    };

    const onSSNLoginFormSubmit = async (form: LoginWithSSN) => {
      ssnForm.value = form;
      await ssnLogin({
        socialSecurityNumber: form.socialSecurityNumber,
      });
    };

    const onCodeLoginFormSubmit = async (form: LoginWithCode) => {
      codeForm.value = form;
      await codeLogin({
        authCode: form.authCode,
        socialSecurityNumber: ssnForm.value.socialSecurityNumber,
      });
    };

    const onLoginFormSubmit = async (form: LoginWithPassword) => {
      withPasswordForm.value = form;
      const { token, cleanup } = await getRecaptchaInfo(isRecaptchaEnabled.value);
      await login({ user: { ...form, ...(token ? { recaptchaToken: token } : {}) } });
      cleanup();
      if (!userError.value.login) {
        await Promise.all([loadItemsCount(), loadCart()]);
      }
    };

    const toggleModal = () => {
      if (!isModalVisible.value) resetCodeReceived();
      isModalVisible.value = !isModalVisible.value;
    };

    return {
      withPasswordForm,
      bankIdForm,
      ssnForm,
      codeForm,
      breadcrumbs,
      onLoginFormSubmit,
      onBankIdLoginFormSubmit,
      onSSNLoginFormSubmit,
      onCodeLoginFormSubmit,
      isRecaptchaEnabled,
      loading,
      isBankIdQrCodeModalOpen,
      qrCodeValue,
      loginWithEmail,
      isModalVisible,
      toggleModal,
      getCodeLoading,
      codeReceived,
    };
  },
});
