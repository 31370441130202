















































import type { PropType } from '@nuxtjs/composition-api';
import { defineComponent, ref, watch } from '@nuxtjs/composition-api';
import { SfButton, SfInput, SfLoader } from '@storefront-ui/vue';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { LoginWithSSN } from '~/modules/customer/types/form';
import { FormName } from './types';

// const { isOpen, open, close } = useDisclosure({ initialValue: false });

extend('required', {
  ...required,
  message: 'This field is required',
});

export default defineComponent({
  name: 'SSNLoginForm',
  components: {
    SfInput,
    SfButton,
    SfLoader,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    form: {
      type: Object as PropType<LoginWithSSN>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    showRecaptcha: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const formCopy = ref<LoginWithSSN>();
    const isModalVisible = ref(false);

    watch(
      () => props.form,
      (newForm) => {
        formCopy.value = { ...newForm };
      },
      { immediate: true, deep: true },
    );

    const changeForm = (formToNavigateTo: FormName) => {
      emit('change-form', formToNavigateTo);
    };

    return {
      formCopy,
      changeForm,
      isModalVisible,
    };
  },
});
