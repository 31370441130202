




import { defineComponent, onBeforeUnmount, onMounted } from '@nuxtjs/composition-api';
import useBankId from '../../composables/useBankId';

export default defineComponent({
  name: 'BankIdAnchor',
  setup() {
    const { cancelBankIdProcess } = useBankId();
    const timeToCancel = 2500;
    let cancelTimeout: number | null = null;

    function clearCancelTimeout() {
      if (cancelTimeout !== null) {
        clearTimeout(cancelTimeout);
      }
    }

    function onBlur() {
      clearCancelTimeout();
    }

    function onFocus() {
      clearCancelTimeout();
      cancelTimeout = window.setTimeout(() => {
        cancelBankIdProcess();
        window.removeEventListener('blur', onBlur);
        window.removeEventListener('focus', onFocus);
      }, timeToCancel);
    }

    onMounted(() => {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'attributes' && mutation.attributeName === 'href') {
            window.addEventListener('blur', onBlur);
            window.addEventListener('focus', onFocus);
          }
        });
      });

      const anchorElement = document.querySelector('#start-bankid-anchor');
      if (anchorElement) {
        observer.observe(anchorElement, {
          attributes: true,
        });
      }
    });

    onBeforeUnmount(() => {
      window.removeEventListener('blur', onBlur);
      window.removeEventListener('focus', onFocus);
    });

    return {};
  },
});
