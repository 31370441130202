



























































import type { PropType } from '@nuxtjs/composition-api';
import { defineComponent, ref, watch } from '@nuxtjs/composition-api';
import { SfButton, SfInput, SfLink, SfLoader } from '@storefront-ui/vue';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import SvgImage from '~/components/General/SvgImage.vue';
import { LoginFormFields } from './types';

extend('required', {
  ...required,
  message: 'This field is required',
});

export default defineComponent({
  components: {
    SfInput,
    SfButton,
    SfLoader,
    SfLink,
    ValidationObserver,
    ValidationProvider,
    SvgImage,
  },
  props: {
    form: {
      type: Object as PropType<LoginFormFields>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    showRecaptcha: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const formCopy = ref<LoginFormFields>();
    const isVisible = ref(false);

    const toggleVisibility = () => {
      isVisible.value = !isVisible.value;
    };

    watch(
      () => props.form,
      (newForm) => {
        formCopy.value = { ...newForm };
      },
      { immediate: true, deep: true },
    );

    return {
      formCopy,
      isVisible,
      toggleVisibility,
    };
  },
});
