var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"title",on:{"click":_vm.toggleVisibility}},[_vm._v("\n    "+_vm._s(_vm.$t('Login with password'))+"\n    "),_c('SvgImage',{staticClass:"chevron-icon",attrs:{"width":"24","height":"24","icon":"chevron_down"}})],1),_vm._v(" "),(_vm.isVisible)?_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();handleSubmit(function () {
          _vm.$emit('submit', _vm.formCopy);
        })}}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-email'),expression:"'login-modal-email'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"email","label":_vm.$t('Email address / Personal number')},model:{value:(_vm.formCopy.email),callback:function ($$v) {_vm.$set(_vm.formCopy, "email", $$v)},expression:"formCopy.email"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-password'),expression:"'login-modal-password'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"password","label":_vm.$t('Password'),"type":"password","has-show-password":""},model:{value:(_vm.formCopy.password),callback:function ($$v) {_vm.$set(_vm.formCopy, "password", $$v)},expression:"formCopy.password"}})]}}],null,true)}),_vm._v(" "),(_vm.showRecaptcha)?_c('recaptcha'):_vm._e(),_vm._v(" "),_vm._t("error"),_vm._v(" "),_c('SfLink',{staticClass:"form__forgot-password sf-link--primary",attrs:{"link":_vm.localePath('/forgot-password')}},[_vm._v("\n        "+_vm._s(_vm.$t('Forgotten password?'))+"\n      ")]),_vm._v(" "),_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-submit'),expression:"'login-modal-submit'"}],staticClass:"sf-button--full-width form__button color-secondary",attrs:{"type":"submit","disabled":_vm.loading,"data-testid":"login-form-submit"}},[_c('SfLoader',{class:{ loader: _vm.loading },attrs:{"loading":_vm.loading}},[_c('div',[_vm._v(_vm._s(_vm.$t('Login')))])])],1)],2)]}}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }